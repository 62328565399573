import React from 'react';

const AppContext = React.createContext({
  alerts: [],
  isAppBarShowing: false,
  isAuthenticated: false,
  isLoading: true,
  logout: () => {},
  setAuthenticated: () => {}
});

export default AppContext;

const AppContextConsumer = AppContext.Consumer;
const AppContextProvider = AppContext.Provider;

export { AppContextProvider, AppContextConsumer };
