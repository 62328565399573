import * as PropTypes from 'prop-types';

/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Loadable from 'react-loadable';

import PATHS from './paths';

export const Loading = ({ error, retry, pastDelay }) => {
  if (error) {
    return (
      <div>
        Error!
        <button onClick={retry} type="button">
          Retry
        </button>
      </div>
    );
  }
  if (pastDelay) {
    return <div>Loading...</div>;
  }

  return null;
};

Loading.propTypes = {
  error: PropTypes.bool,
  retry: PropTypes.func.isRequired,
  pastDelay: PropTypes.bool.isRequired
};

Loading.defaultProps = {
  error: null
};

const Dashboard = Loadable({
  loader: () => import('./Dashboard'),
  loading: Loading,
  delay: 300
});

const Login = Loadable({
  loader: () => import('./Login'),
  loading: Loading,
  delay: 300
});

const SalesOrderDetail = Loadable({
  loader: () => import('./SalesOrderDetail'),
  loading: Loading,
  delay: 300
});

SalesOrderDetail.preload();

const routes = [
  {
    path: PATHS.DASHBOARD,
    component: Dashboard,
    exact: true,
    protected: true,
    showAppBar: true
  },
  {
    path: PATHS.SALES_ORDER_DETAIL,
    component: SalesOrderDetail,
    exact: true,
    protected: true,
    showAppBar: true
  },
  {
    path: PATHS.LOGIN,
    exact: true,
    component: Login
  }
];

export default routes;
