/* eslint-disable react/no-unused-state */
import React, { Component, Suspense } from 'react';

import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { Switch, withRouter } from 'react-router';
import AppContext from './components/util/AppContext';
import feathersClient from './feathers';
import PATHS from './components/pages/paths';
import routes from './components/pages/routes';
import RouteWithSubRoutes from './components/util/RouteWithSubRoutes';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1565c0'
    },
    secondary: {
      main: '#e65100'
    }
  }
});

class App extends Component {
  state = { isLoading: true, triedAuth: false };

  constructor(props) {
    super(props);

    const {
      companyId,
      login,
      logout,
      setAuthenticated,
      setCompanyId,
      showAppBar
    } = this;

    this.state = {
      alerts: [],
      companyId,
      credentials: {},
      client: feathersClient,
      isAppBarShowing: false,
      isAuthenticated: false,
      isLoading: true,
      login,
      logout,
      setAuthenticated,
      setCompanyId,
      showAppBar,
      triedAuth: false,
      user: null
    };
  }

  componentDidMount = async () => {
    const { triedAuth } = this.state;

    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (!triedAuth) {
      await this.login();
    }
  };

  login = async ({ email, password } = {}) => {
    const { client } = this.state;

    let args;

    if (email && password) {
      this.setState({ credentials: { email, password } });
      args = {
        strategy: 'local',
        email,
        password
      };
    }

    try {
      const response = await client.authenticate(args);
      const jwt = await client.passport.verifyJWT(response.accessToken);
      const user = await client.service('users').get(jwt.userId);

      if (user && user.companies.length === 1) {
        this.setCompanyId(user.companies[0].id);
        this.setUser(user);
      }

      this.setState({
        isAuthenticated: true,
        isLoading: false,
        triedAuth: true
      });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, triedAuth: true });
    }
  };

  showAppBar = ({ isAppBarShowing }) => {
    this.setState({ isAppBarShowing });
  };

  logout = async () => {
    const { history } = this.props;

    await feathersClient.logout();
    history.replace(PATHS.LOGIN);
  };

  setAuthenticated = isAuthenticated => {
    this.setState({ isAuthenticated });
  };

  setCompanyId = companyId => {
    this.setState({ companyId });
    window.localStorage.setItem('companyId', companyId);
  };

  setUser = user => {
    this.setState({ user });
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <AppContext.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <Suspense fallback={null}>
              <CssBaseline />
              <Switch>
                {routes.map(route => (
                  <RouteWithSubRoutes key={route.path} {...route} />
                ))}
              </Switch>
            </Suspense>
          </MuiThemeProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
