import feathers from '@feathersjs/client';
import { CookieStorage } from 'cookie-storage';
import io from 'socket.io-client';
import env from '../utils/util-env';

const { authentication, socketio } = feathers;
const app = feathers();
const socket = io(env`OTCLOUD_APP_AUTH_HOST`);

app.configure(
  socketio(socket, {
    timeout: 2000
  })
);
app.configure(
  authentication({
    cookie: 'otcloud-jwt',
    storageKey: 'otcloud-jwt',
    storage: new CookieStorage()
  })
);

export default app;
