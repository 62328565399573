import { Route } from 'react-router-dom';
import React from 'react';
import { Redirect } from 'react-router';
import AppContext from '../AppContext';
import PATHS from '../../pages/paths';

export default route => (
  <AppContext.Consumer>
    {context => (
      <Route
        exact={route.exact}
        path={route.path}
        render={(props) => {
          if (!context.isAuthenticated && route.protected && route.path !== PATHS.LOGIN) {
            return (
              <Redirect
                to={{
                  pathname: PATHS.LOGIN,
                  state: { from: props.location }
                }}
              />
            );
          }

          return (
          // pass the sub-routes down to keep nesting
            <route.component
              {...props}
              ctx={context}
              exact={route.exact}
              routes={route.routes}
            />
          );
        }}
      />
    )}
  </AppContext.Consumer>
);
