import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import env from '../utils/util-env';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    setContext((_, { headers }) => {
      // get company id
      const companyId = localStorage.getItem('companyId');

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          'X-Company-Id': companyId || ''
        }
      };
    }),
    new BatchHttpLink({
      credentials: 'same-origin',
      uri: env('OTCLOUD_APP_INTEGRATION_ROOT')
    })
  ]),
  cache: new InMemoryCache()
});

export default client;
